import _filter from "lodash/filter";
import produce, { Draft } from "immer";
import { Reducer } from "use-immer";

const appReducer: Reducer<State, CreateAction> = produce(
  (draftState: Draft<State>, action: CreateAction) => {
    switch (action.type) {
      case "finish-poll-creation":
        draftState.poll.id = action.id;
        draftState.currentStep += 1;
        return;
      case "change-location":
        draftState.poll.location = action.location;
        return;
      case "change-title":
        draftState.poll.title = action.title;
        return;
      case "change-place-id":
        draftState.poll.placeID = action.placeID;
        return;
      case "toggle-continue-btn":
        draftState.canContinue = action.value;
        return;
      case "add-option":
        draftState.poll.options.push(action.option);
        return;
      case "delete-option":
        draftState.poll.options = _filter(
          draftState.poll.options,
          (opt, index) => {
            if (action.index) {
              return index !== action.index;
            }
            return opt !== action.option;
          }
        );
        return;
      case "next-step":
        draftState.currentStep += 1;
        return;
      case "prev-step":
        draftState.currentStep -= 1;
        return;
      case "reset-options":
        draftState.poll.options = [];
        return;
      case "update-option":
        draftState.poll.options.reduce((acc: string[], val, index) => {
          if (index === action.index) {
            acc[index] = action.option;
          } else {
            acc[index] = val;
          }
          return acc;
        }, []);
        if (action.index + 1 === draftState.poll.options.length) {
          draftState.poll.options.push("");
        }
        return;
      case "change-creator-email":
        draftState.poll.creator.email = action.email;
        return;
      case "change-creator-name":
        draftState.poll.creator.name = action.name;
        return;
      case "set-options-type":
        draftState.poll.optionsType = action.optionType;
        return;
    }
  }
);

export default appReducer;
