import React from "react";
import { css } from "emotion";
import { Global } from "@emotion/core";
import { Router } from "@reach/router";
import Home from "./screens/Home";
import Poll from "./screens/Poll";

const App: React.FC = () => {
  return (
    <div
      className={css({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 20
      })}
    >
      <Global
        styles={`
          @import url('https://rsms.me/inter/inter.css');
          html { font-family: 'Inter', sans-serif !important; }
          @supports (font-variation-settings: normal) {
            html { font-family: 'Inter var', sans-serif; }
          }
      `}
      />
      <Router>
        <Home path="/" />
        <Poll path="/poll/:id" />
      </Router>
    </div>
  );
};

export default App;
