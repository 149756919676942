import React, { useState, useEffect } from "react";
import { Global } from "@emotion/core";
import Calendar from "react-calendar";
import { isEqual, format, parse } from "date-fns";
import _forEach from "lodash/forEach";
import _every from "lodash/every";
import _findIndex from "lodash/findIndex";
import { css } from "emotion";
import { Button, TextField } from "@shopify/polaris";
import {
  DeleteMajorMonotone,
  MobilePlusMajorMonotone
} from "@shopify/polaris-icons";
import { TabDescriptor } from "@shopify/polaris/types/components/Tabs/types";
import { StepProps } from "../Home";

const dateFormat = "dd/MM/yyyy";

const tabs: TabDescriptor[] = [
  {
    id: "date",
    content: "Date options",
    accessibilityLabel: "Date options",
    panelID: "dates"
  },
  {
    id: "text",
    content: "Text options",
    accessibilityLabel: "Text options",
    panelID: "text"
  }
];

const OptionsSelection: React.FC<StepProps> = ({
  state,
  dispatch
}: StepProps) => {
  // eslint-disable-next-line
  const [selectedTab, setSelectedTab] = useState<number>(
    _findIndex(tabs, { panelID: state.poll.optionsType })
  );

  useEffect(() => {
    if (
      !state.poll.options.length ||
      _every(state.poll.options, opt => opt.trim() === "")
    ) {
      dispatch({ type: "toggle-continue-btn", value: false });
    } else {
      dispatch({ type: "toggle-continue-btn", value: true });
    }
  }, [state.poll.options, dispatch]);

  const handleChange = (date: Date) => {
    const dateString = format(date, dateFormat);
    if (state.poll.options.indexOf(dateString) !== -1) {
      dispatch({ type: "delete-option", option: dateString });
    } else {
      dispatch({ type: "add-option", option: dateString });
    }
  };

  const compareDates = ({ date }: { date: Date }): string => {
    let className: string = "";
    _forEach(state.poll.options, selectedDate => {
      if (isEqual(parse(selectedDate, dateFormat, new Date()), date)) {
        return (className = "hd");
      }
      return null;
    });
    return className;
  };

  const handleOptionChange = (option: string, index: number): void => {
    dispatch({ type: "update-option", option, index });
  };

  const handleDeleteOption = (option: string, index: number): void => {
    dispatch({ type: "delete-option", option, index });
  };

  const renderTextOptions = () => {
    if (state.poll.options.length === 0) {
      return (
        <div
          className={css`
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <Button
            plain
            textAlign="center"
            onClick={() => dispatch({ type: "add-option", option: "" })}
            icon={MobilePlusMajorMonotone}
            size="slim"
          >
            Add a text option
          </Button>
        </div>
      );
    }
    return state.poll.options.map((option, index) => (
      <div
        key={index}
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <div
          className={css`
            flex: 1;
            margin-right: 10px;
          `}
        >
          <TextField
            name={`options.${index}`}
            label={`Option ${index + 1}`}
            value={option}
            onChange={value => handleOptionChange(value, index)}
            autoComplete={false}
          />
        </div>
        <div
          className={css`
            color: #bf0711;
            padding-top: 24px;
          `}
        >
          <Button
            plain
            monochrome
            icon={DeleteMajorMonotone}
            onClick={() => handleDeleteOption(option, index)}
          />
        </div>
      </div>
    ));
  };

  const renderTab = (selected: number) => {
    switch (selected) {
      case 1:
        return renderTextOptions();
      default:
        return (
          <Calendar
            onClickDay={handleChange}
            value={new Date()}
            tileClassName={compareDates}
            minDate={new Date()}
            activeStartDate={undefined}
          />
        );
    }
  };

  // const handleTabSelect = (i: number) => {
  //   dispatch({ type: "reset-options" });
  //   dispatch({ type: "set-options-type", optionType: tabs[i].panelID });
  //   setSelectedTab(i);
  // };

  return (
    <>
      <Global
        styles={`
          .hd {
            background-color: #606ec6 !important;
            color: white;
          }
          .react-calendar__tile--active:not(.hd) {
            background-color: white;
            color: rgba(0, 0, 0, 0.847);
            &.react-calendar__month-view__days__day--weekend {
              color: #d10000;
            }
          }
          .react-calendar {
            border-color: #e1e3e5
          }
        `}
      />
      <div
        className={css`
          width: 100%;
          display: flex;
          justify-content: center;
        `}
      >
        <div
          className={css`
            width: 350px;
            padding-top: 10px;
          `}
        >
          {renderTab(selectedTab)}
        </div>
      </div>
    </>
  );
};

export default OptionsSelection;
