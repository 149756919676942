import React from "react";
import { Card, Heading, Subheading } from "@shopify/polaris";
import { RouteComponentProps } from "@reach/router";
import { css } from "emotion";
import produce, { Draft } from "immer";
import { useImmerReducer, Reducer } from "use-immer";

import { useFirebase } from "../../context/firebase";
import { usePoll } from "../../hooks/usePoll";
import DatesOptions from "./components/DatesOptions";
import { parseDate } from "../../utils";

interface Props {
  id: string;
}

const reducer: Reducer<ViewState, ViewAction> = produce(
  (draftState: Draft<ViewState>, action: ViewAction) => {
    switch (action.type) {
      case "change-name":
        draftState.vote.name = action.name;
        break;
      case "add-option":
        draftState.vote.selectedOptions.push(action.option);
        break;
      case "remove-option":
        draftState.vote.selectedOptions = draftState.vote.selectedOptions.filter(
          (opt: string) =>
            parseDate(opt).getTime() !== parseDate(action.option).getTime()
        );
        break;
      case "reset-input-fields":
        draftState.vote.name = "";
        draftState.vote.selectedOptions = [];
        break;
    }
  }
);

const Poll: React.FC<RouteComponentProps<Props>> = ({
  id
}: RouteComponentProps<Props>) => {
  const { db } = useFirebase();
  const { poll } = usePoll(id);
  const [state, dispatch] = useImmerReducer(reducer, {
    vote: { name: "", selectedOptions: [] }
  });

  const vote = async () => {
    dispatch({ type: "reset-input-fields" });
    await db
      .collection("polls")
      .doc(id)
      .collection("votes")
      .add({
        ...state.vote,
        creationDate: new Date()
      });
  };

  if (!poll) return <div>loading...</div>;
  const canVote =
    state.vote.name.trim() !== "" && state.vote.selectedOptions.length > 0;
  return (
    poll && (
      <div
        className={css`
          width: 800px;
          text-align: center;
        `}
      >
        <Card
          sectioned
          title={<Heading>{poll.title}</Heading>}
          primaryFooterAction={{
            content: "Vote",
            disabled: !canVote,
            onAction: vote
          }}
        >
          <div
            className={css`
              display: flex;
              flex-direction: column;
            `}
          >
            <div
              className={css`
                margin-bottom: 30px;
              `}
            >
              <Subheading>Created by: {poll.creator.name}</Subheading>
            </div>
            {poll.optionsType === "dates" && (
              <DatesOptions poll={poll} state={state} dispatch={dispatch} />
            )}
          </div>
        </Card>
      </div>
    )
  );
};

export default Poll;
