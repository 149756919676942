import React from "react";
import ReactDOM from "react-dom";
import { AppProvider } from "@shopify/polaris";
import { FirebaseProvider } from "./context/firebase";

import enTranslations from "@shopify/polaris/locales/en.json";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <FirebaseProvider>
    <AppProvider i18n={enTranslations}>
      <App />
    </AppProvider>
  </FirebaseProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
