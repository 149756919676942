import React from "react";
import { css } from "emotion";
import { useVotes } from "../../../hooks/useVotes";
import { Icon, TextField, Checkbox } from "@shopify/polaris";
import { TickMinor } from "@shopify/polaris-icons";
import { parseDate } from "../../../utils";

interface Props {
  state: ViewState;
  dispatch: React.Dispatch<ViewAction>;
  poll: Poll;
}

const DatesOptions: React.FC<Props> = ({ poll, state, dispatch }) => {
  const votes = useVotes(poll.id);
  return (
    <table
      className={css`
        border-collapse: collapse;
        border: 1px solid rgb(213, 217, 221);
        margin: 0 auto;
        td {
          border: 1px solid rgb(213, 217, 221);
          padding: 1.2rem;
        }
      `}
    >
      <TableHeader poll={poll} votes={votes} />
      <tbody>
        <Votes poll={poll} votes={votes} />
        <tr>
          <td>
            <TextField
              label=""
              labelHidden
              autoComplete="off"
              placeholder="Your name"
              value={state.vote.name}
              onChange={name => dispatch({ type: "change-name", name })}
            />
          </td>
          {poll.options.map(opt => (
            <td key={parseDate(opt).getTime()}>
              <div
                className={css`
                  text-align: center;
                `}
              >
                <Checkbox
                  onChange={checked => {
                    if (checked) {
                      dispatch({ type: "add-option", option: opt });
                    } else {
                      dispatch({ type: "remove-option", option: opt });
                    }
                  }}
                  // checked={state.vote.selectedOptions
                  //   .map(date => date.getTime())
                  //   .includes(opt.getTime())}
                  label=""
                  labelHidden
                  checked={state.vote.selectedOptions.includes(opt)}
                />
              </div>
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

const TableHeader: React.FC<{ poll: Poll; votes: Vote[] }> = ({ poll }) => {
  const votes = useVotes(poll.id);
  const dates = poll.options.flatMap(opt => parseDate(opt));

  const votesTotal = dates.map(
    opt =>
      votes
        .flatMap(v => v.selectedOptions)
        .filter(votedOpt => parseDate(votedOpt).getTime() === opt.getTime())
        .length
  );

  return (
    <thead>
      <tr>
        <th />
        {dates.map((opt, idx) => (
          <th
            key={opt.getTime()}
            className={css`
              padding: 1.6rem;
            `}
          >
            <div
              className={css`
                position: relative;
                display: flex;
                flex-direction: column;
                width: 6.1rem;
                border-radius: 3px;
                margin: 0 auto;
              `}
            >
              <span
                className={css`
                  font-size: 1.6rem;
                  padding: 0.4rem 1.6rem;
                  font-weight: 400;
                  color: #fff;
                  background-color: #de3618;
                `}
              >
                {opt.toLocaleString("default", { month: "short" })}
              </span>
              <span
                className={css`
                  font-size: 1.8rem;
                  padding: 0.8rem 1.2rem;
                  font-weight: 400;
                  color: #454f5b;
                  border: 1px solid #ccc;
                  border-top: none;
                `}
              >
                {opt.getDate()}
              </span>
              {votesTotal[idx] > 0 && (
                <div
                  className={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #414f3e;
                    font-weight: 600;
                    position: absolute;
                    top: -0.9rem;
                    right: -0.9rem;
                    font-size: 1.3rem;
                    background: ${Math.max(...votesTotal) === votesTotal[idx]
                      ? "#bbe5b3"
                      : "#dfe3e8"};
                    height: 2rem;
                    width: 2rem;
                    border-radius: 100px;
                    border: 0.2rem solid #fff;
                  `}
                >
                  {votesTotal[idx]}
                </div>
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};

const Votes: React.FC<{ poll: Poll; votes: Vote[] }> = ({ poll, votes }) => {
  return (
    <>
      {votes.map(vote => (
        <tr key={vote.id}>
          <td>
            <div
              className={css`
                text-align: center;
              `}
            >
              {vote.name}
            </div>
          </td>
          {poll.options.map(opt => (
            <td key={`${vote.id}-${parseDate(opt).getTime()}`}>
              <div
                className={css`
                  text-align: center;
                `}
              >
                {vote.selectedOptions.includes(opt) && (
                  <Icon source={TickMinor} color={"greenDark"} />
                )}
              </div>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default DatesOptions;
