import React, { useEffect, useState } from "react";
import { TextField } from "@shopify/polaris";
import { css } from "emotion";
import { StepProps } from "../Home";

const CreatorInfoForm: React.FC<StepProps> = ({
  state,
  dispatch
}: StepProps) => {
  const [emailTouched, setEmailTouched] = useState(false);
  const emailValid = (val: string) =>
    /^[a-z0-9][a-z0-9-_.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/.test(
      val
    );

  const emailErr =
    !emailValid(state.poll.creator.email) && emailTouched
      ? "Please enter a valid email address."
      : "";

  useEffect(() => {
    if (
      state.poll.creator.email.trim() !== "" &&
      emailValid(state.poll.creator.email) &&
      state.poll.creator.name.trim() !== ""
    ) {
      dispatch({ type: "toggle-continue-btn", value: true });
    } else {
      dispatch({ type: "toggle-continue-btn", value: false });
    }
  }, [dispatch, state.poll.creator]);

  return (
    <div>
      <div
        className={css`
          margin-bottom: 1.6rem;
        `}
      >
        <TextField
          label="Name"
          value={state.poll.creator.name}
          onChange={value =>
            dispatch({ type: "change-creator-name", name: value })
          }
        />
      </div>
      <TextField
        label="Email"
        value={state.poll.creator.email}
        onBlur={() => setEmailTouched(true)}
        onChange={value =>
          dispatch({ type: "change-creator-email", email: value })
        }
        type="email"
        error={emailErr}
      />
    </div>
  );
};

export default CreatorInfoForm;
