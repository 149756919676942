import React from "react";
import * as firebase from "firebase/app";
import "firebase/firestore";
// import "firebase/auth";

const config = {
  apiKey: "AIzaSyC10AQNCMqYia43cCA6__Gasy4WYyRuyvc",
  authDomain: "gtools-fa93c.firebaseapp.com",
  databaseURL: "https://gtools-fa93c.firebaseio.com",
  projectId: "gtools-fa93c",
  storageBucket: "gtools-fa93c.appspot.com",
  messagingSenderId: "1042734005311",
  appId: "1:1042734005311:web:30ca75d2553b8936"
};

const firebaseApp = firebase.initializeApp(config);

const FirebaseContext = React.createContext<undefined | firebase.app.App>(
  undefined
);

function FirebaseProvider({ children }: React.PropsWithChildren<{}>) {
  return (
    <FirebaseContext.Provider value={firebaseApp}>
      {children}
    </FirebaseContext.Provider>
  );
}

function useFirebase() {
  const context = React.useContext(FirebaseContext);
  if (!context) {
    throw new Error("useFirebase must be called within a FirebaseProvider");
  }
  return {
    firebaseApp: context,
    // auth: context.auth(),
    db: context.firestore()
  };
}

export { FirebaseProvider, useFirebase };
