import React from "react";
import { TextField, Button } from "@shopify/polaris";
import { css } from "emotion";
import { ClipboardMinor } from "@shopify/polaris-icons";
import { StepProps } from "../Home";

const SharePollView: React.FC<StepProps> = ({ state }: StepProps) => {
  return (
    <div
      className={css`
        min-height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        > div {
          min-width: 400px;
        }
      `}
    >
      <TextField
        autoComplete={false}
        value={`${window.location.href}poll/${state.poll.id}`}
        readOnly={true}
        label={""}
        connectedRight={
          <Button
            onClick={() => {
              const input = document.querySelector(
                ".Polaris-TextField__Input"
              ) as HTMLInputElement;
              if (input) {
                input.select();
                document.execCommand("copy");
              }
            }}
            primary
            icon={ClipboardMinor}
          >
            Copy
          </Button>
        }
      />
    </div>
  );
};

export default SharePollView;
