import { useEffect, useState } from "react";
import { geocodeByPlaceId } from "react-places-autocomplete";
import { useFirebase } from "../context/firebase";

function usePoll(id?: string) {
  const { db } = useFirebase();
  const [poll, setPoll] = useState<undefined | Poll>(undefined);
  const [placeData, setPlaceData] = useState<
    undefined | google.maps.GeocoderResult[]
  >(undefined);

  useEffect(() => {
    const pollRef = db.collection("polls").doc(id);
    pollRef.get().then(doc => {
      const pollData = doc.data() as Poll;
      if (pollData) {
        pollData.id = doc.id;
        if (pollData.location === "place") {
          geocodeByPlaceId(pollData.placeID).then(result => {
            setPlaceData(result);
          });
        }
        setPoll(pollData);
      }
    });
  }, [db, id]);
  return { placeData, poll };
}

export { usePoll };
