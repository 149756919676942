import React, { useState } from "react";
import { RouteComponentProps } from "@reach/router";
import { Card, Heading, Subheading } from "@shopify/polaris";
import { Global } from "@emotion/core";
import { css } from "emotion";
import { useImmerReducer } from "use-immer";

import { useFirebase } from "../../context/firebase";
import appReducer from "../../appReducer";

import OccasionForm from "./components/OccasionForm";
import OptionsSelection from "./components/OptionsSelection";
import CreatorInfoForm from "./components/CreatorInfoForm";
import SharePollView from "./components/SharePollView";

export interface StepProps {
  state: State;
  dispatch: React.Dispatch<CreateAction>;
}

const STEPS_TITLES = [
  "What's the occasion?",
  "What are the options?",
  "Tell your participants who you are",
  "Invite participants!"
];

const Home: React.FC<RouteComponentProps> = () => {
  const [loading, setLoading] = useState(false);
  const [state, dispatch] = useImmerReducer(appReducer, {
    currentStep: 1,
    poll: {
      title: "",
      location: "",
      placeID: "",
      options: [],
      creator: {
        name: localStorage.getItem("recentName") || "",
        email: localStorage.getItem("recentEmail") || ""
      },
      optionsType: "dates"
    },
    canContinue: false
  });

  const { db } = useFirebase();

  const finishPollCreation = async () => {
    setLoading(true);
    try {
      const doc = await db.collection("polls").add(state.poll);
      dispatch({ type: "finish-poll-creation", id: doc.id });
      localStorage.setItem("recentName", state.poll.creator.name);
      localStorage.setItem("recentEmail", state.poll.creator.email);
      setLoading(false);
    } catch {
      console.error("failed to add poll!");
    }
  };

  const getStep = (step: number) => {
    switch (step) {
      case 1:
        return <OccasionForm state={state} dispatch={dispatch} />;
      case 2:
        return <OptionsSelection state={state} dispatch={dispatch} />;
      case 3:
        return <CreatorInfoForm state={state} dispatch={dispatch} />;
      case 4:
        return <SharePollView state={state} dispatch={dispatch} />;
    }
  };

  return (
    <div className={css({ width: 800 })}>
      <Global
        styles={`
          .Polaris-Heading {
            font-size: 2.5rem;
            text-align: center; 
          }
          .Polaris-Subheading {
            text-align: center;
          }
        `}
      />
      <Card
        sectioned
        title={<Heading>{STEPS_TITLES[state.currentStep - 1]}</Heading>}
        primaryFooterAction={
          state.currentStep < 4
            ? {
                content: "Continue",
                disabled: !state.canContinue,
                loading,
                onAction:
                  state.currentStep !== 3
                    ? () => dispatch({ type: "next-step" })
                    : finishPollCreation
              }
            : undefined
        }
        secondaryFooterActions={
          state.currentStep < 4
            ? [
                {
                  content: "Back",
                  disabled: state.currentStep < 2 || loading,
                  onAction: () => dispatch({ type: "prev-step" })
                }
              ]
            : undefined
        }
      >
        {state.currentStep < 4 && (
          <Subheading>
            Step {state.currentStep} of {STEPS_TITLES.length}
          </Subheading>
        )}
        <div
          className={css`
            margin: 15px;
          `}
        />
        {getStep(state.currentStep)}
      </Card>
    </div>
  );
};

export default Home;
