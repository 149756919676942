import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress
} from "react-places-autocomplete";
import { css } from "emotion";
import { Autocomplete, Icon } from "@shopify/polaris";
import { LocationMajorMonotone } from "@shopify/polaris-icons";

interface PropTypes {
  currentPlace: string;
  onPlaceChange: (place: string, placeID: string) => void;
  setLocationValue: (location: string) => void;
}

const LocationInput: React.FC<PropTypes> = ({
  currentPlace,
  setLocationValue,
  onPlaceChange
}: PropTypes) => {
  const handlePlaceSelect = (place: string) => {
    geocodeByAddress(place).then(results =>
      onPlaceChange(place, results[0].place_id)
    );
  };

  const getOptions = (options: any[]) => {
    return options.map(opt => {
      return {
        label: opt.description,
        value: opt.description
      };
    });
  };

  return (
    <div
      className={css`
        margin-bottom: 1.6rem;
      `}
    >
      <PlacesAutocomplete
        onSelect={handlePlaceSelect}
        onChange={e => setLocationValue(e)}
        value={currentPlace}
      >
        {({ suggestions, loading, getInputProps }) => (
          <Autocomplete
            options={getOptions(JSON.parse(JSON.stringify(suggestions)))}
            selected={[currentPlace]}
            onSelect={selected => handlePlaceSelect(selected[0])}
            loading={loading}
            textField={
              <Autocomplete.TextField
                onChange={value =>
                  getInputProps().onChange({ target: { value } })
                }
                label="Place"
                value={currentPlace}
                prefix={
                  <Icon source={LocationMajorMonotone} color="inkLighter" />
                }
                placeholder="Search"
              />
            }
            allowMultiple={false}
          />
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default LocationInput;
