import React, { useState, useEffect } from "react";
import { Field, Formik } from "formik";
import { TextField, Select } from "@shopify/polaris";
import { css } from "emotion";
import { geocodeByPlaceId } from "react-places-autocomplete";
import { StepProps } from "../Home";
import LocationInput from "../../../components/LocationInput";

const OccasionForm: React.FC<StepProps> = ({ state, dispatch }: StepProps) => {
  const options = [
    { label: "To be decided", value: "tbd" },
    { label: "Phone", value: "phone" },
    { label: "Skype", value: "skype" },
    { label: "Google Hangout", value: "hangout" },
    { label: "Place", value: "place" }
  ];
  const [locationValue, setLocationValue] = useState("");

  useEffect(() => {
    if (state.poll.title && state.poll.title.trim() !== "") {
      dispatch({ type: "toggle-continue-btn", value: true });
    } else {
      dispatch({ type: "toggle-continue-btn", value: false });
    }
    if (state.poll.location === "place" && !state.poll.placeID) {
      dispatch({ type: "toggle-continue-btn", value: false });
    }
  }, [state.poll.title, dispatch, state.poll.placeID, state.poll.location]);

  useEffect(() => {
    if (state.poll.placeID) {
      geocodeByPlaceId(state.poll.placeID).then(result =>
        setLocationValue(result[0].formatted_address)
      );
    }
  }, [state.poll.placeID]);

  const handlePlaceSelect = (place: string, placeID: string) => {
    setLocationValue(place);
    dispatch({ type: "change-place-id", placeID: placeID });
  };

  return (
    <Formik
      initialValues={{ title: "", location: "tbd", note: "" }}
      onSubmit={e => console.log(e)}
    >
      {() => (
        <form onSubmit={ev => ev.preventDefault()}>
          <div
            className={css`
              margin-bottom: 1.6rem;
            `}
          >
            <Field
              value={state.poll.title}
              onChange={(title: string) =>
                dispatch({ type: "change-title", title })
              }
              label="What's the occasion ?"
              component={TextField}
              className={css`
                margin-bottom: 10px;
              `}
            />
          </div>
          <div
            className={css`
              margin-bottom: 1.6rem;
            `}
          >
            <Select
              label="Location"
              options={options}
              value={state.poll.location}
              onChange={(location: string) =>
                dispatch({ type: "change-location", location })
              }
            />
          </div>
          {state.poll.location === "place" && (
            <LocationInput
              currentPlace={locationValue}
              setLocationValue={setLocationValue}
              onPlaceChange={handlePlaceSelect}
            />
          )}
        </form>
      )}
    </Formik>
  );
};

export default OccasionForm;
